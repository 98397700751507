import React from 'react';

import { ImageContentSection } from './contentTypes';
import { Exclude2x, Image, ImageProps } from './Image';
import { AspectRatio } from './ImagePlaceholder';
import { ImageThronFromContent } from './ImageThronFromContent';

export interface ImageFromContentProps extends ImageContentSection, Omit<ImageProps, 'src' | 'exclude2x'> {}

export const ImageFromContent = ({ provider, src, ...otherProps }: ImageFromContentProps) => {
  const aspectRatio = React.useMemo<AspectRatio>(() => {
    const result: AspectRatio = {};

    if (provider?.bcom?.xs?.url) {
      if (provider.bcom.xs.width !== undefined && provider.bcom.xs.height !== undefined) {
        result.xs = provider.bcom.xs.width / provider.bcom.xs.height;
      }
      if (provider.bcom.sm?.width !== undefined && provider.bcom.sm.height !== undefined) {
        result.sm = provider.bcom.sm.width / provider.bcom.sm.height;
      }
      if (provider.bcom.md?.width !== undefined && provider.bcom.md.height !== undefined) {
        result.md = provider.bcom.md.width / provider.bcom.md.height;
      }
      if (provider.bcom.lg?.width !== undefined && provider.bcom.lg.height !== undefined) {
        result.lg = provider.bcom.lg.width / provider.bcom.lg.height;
      }
      if (provider.bcom.xl?.width !== undefined && provider.bcom.xl.height !== undefined) {
        result.xl = provider.bcom.xl.width / provider.bcom.xl.height;
      }
      if (provider.bcom.xxl?.width !== undefined && provider.bcom.xxl.height !== undefined) {
        result.xxl = provider.bcom.xxl.width / provider.bcom.xxl.height;
      }
    } else if (src?.xs?.url !== undefined) {
      if (src.xs.width !== undefined && src.xs.height !== undefined) {
        result.xs = src.xs.width / src.xs.height;
      }
      if (src.sm?.width !== undefined && src.sm.height !== undefined) {
        result.sm = src.sm.width / src.sm.height;
      }
      if (src.md?.width !== undefined && src.md.height !== undefined) {
        result.md = src.md.width / src.md.height;
      }
      if (src.lg?.width !== undefined && src.lg.height !== undefined) {
        result.lg = src.lg.width / src.lg.height;
      }
      if (src.xl?.width !== undefined && src.xl.height !== undefined) {
        result.xl = src.xl.width / src.xl.height;
      }
      if (src.xxl?.width !== undefined && src.xxl.height !== undefined) {
        result.xxl = src.xxl.width / src.xxl.height;
      }
    }
    return result;
  }, [provider, src]);

  const exclude2x = React.useMemo<Exclude2x>(() => {
    const result: Exclude2x = {};

    if (provider?.bcom?.xs?.url) {
      if (provider.bcom.xs.density !== undefined) {
        result.xs = provider.bcom.xs.density === 1;
      } else {
        result.xs = true;
      }
      if (provider.bcom.sm?.density !== undefined) {
        result.sm = provider.bcom.sm.density === 1;
      } else {
        result.sm = true;
      }
      if (provider.bcom.md?.density !== undefined) {
        result.md = provider.bcom.md.density === 1;
      } else {
        result.md = true;
      }
      if (provider.bcom.lg?.density !== undefined) {
        result.lg = provider.bcom.lg.density === 1;
      } else {
        result.lg = true;
      }
      if (provider.bcom.xl?.density !== undefined) {
        result.xl = provider.bcom.xl.density === 1;
      } else {
        result.xl = true;
      }
      if (provider.bcom.xxl?.density !== undefined) {
        result.xxl = provider.bcom.xxl.density === 1;
      } else {
        result.xxl = true;
      }
    } else if (src?.xs?.url) {
      if (src.xs.density !== undefined) {
        result.xs = src.xs.density === 1;
      } else {
        result.xs = true;
      }
      if (src.sm?.density !== undefined) {
        result.sm = src.sm.density === 1;
      } else {
        result.sm = true;
      }
      if (src.md?.density !== undefined) {
        result.md = src.md.density === 1;
      } else {
        result.md = true;
      }
      if (src.lg?.density !== undefined) {
        result.lg = src.lg.density === 1;
      } else {
        result.lg = true;
      }
      if (src.xl?.density !== undefined) {
        result.xl = src.xl.density === 1;
      } else {
        result.xl = true;
      }
      if (src.xxl?.density !== undefined) {
        result.xxl = src.xxl.density === 1;
      } else {
        result.xxl = true;
      }
    }
    return result;
  }, [provider, src]);

  return (
    <>
      {provider?.bcom?.xs?.url ? (
        <Image
          {...otherProps}
          aspectRatio={aspectRatio}
          exclude2x={exclude2x}
          src={{
            lg: provider.bcom.lg?.url,
            md: provider.bcom.md?.url,
            sm: provider.bcom.sm?.url,
            xl: provider.bcom.xl?.url,
            xs: provider.bcom.xs.url,
            xxl: provider.bcom.xxl?.url,
          }}
        />
      ) : provider?.thron?.xs?.url ? (
        <ImageThronFromContent {...provider.thron} fluid />
      ) : src?.xs?.url ? (
        <Image
          {...otherProps}
          aspectRatio={aspectRatio}
          exclude2x={exclude2x}
          src={{
            lg: src.lg?.url,
            md: src.md?.url,
            sm: src.sm?.url,
            xl: src.xl?.url,
            xs: src.xs.url,
            xxl: src.xxl?.url,
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};
