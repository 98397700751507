import {
  InitPaymentRequestApiDto,
  OrderApiDto,
  OrderingApiType,
  OrderStateApiType,
  PaymentMethodApiDto,
  PaymentResponseApiDto,
  RetryPaymentRequestApiDto,
  SearchOrderFieldApiType,
  SearchOrdersApiDto,
} from './dto';
import { SkuPopulate } from './products';
import { apiRequest, BaseOptions, getBaseOptions, Populate } from './utils/util';

const rootPath = '/v2/orders';

export interface OrderPopulate {
  billingAddress?: boolean;
  depositPayments?: boolean;
  paymentMethod?: boolean;
  shippingAddress?: boolean;
  warehouseOrders?: Populate<WarehouseOrderPopulate>;
}

export interface WarehouseOrderPopulate {
  carrierTracking?: boolean;
  orderedSkus?: Populate<OrderedSkuPopulate>;
}

export interface OrderedSkuPopulate {
  sku?: Populate<SkuPopulate>;
}

export interface GetOrderOptions extends BaseOptions {
  populate?: Populate<OrderPopulate>;
}

export const get = async (id: string, options?: GetOrderOptions) =>
  apiRequest<OrderApiDto, void, OrderPopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    url: `${rootPath}/${id}`,
  });

// export const getByCode = async (code: string, options?: GetOrderOptions) =>
//   apiRequest<OrderApiDto, void, OrderPopulate>({
//     ...getBaseOptions(options),
//     populate: options?.populate,
//     url: `${rootPath}/code/${code}`,
//   });

export const getByCodeAndEmail = async (code: string, email: string, options?: GetOrderOptions) =>
  apiRequest<OrderApiDto, void, OrderPopulate>({
    ...getBaseOptions(options),
    params: {
      code: code,
      email: email,
    },
    populate: options?.populate,
    url: `${rootPath}`,
  });

export const getOrderByToken = async (token: string, options?: GetOrderOptions) =>
  apiRequest<OrderApiDto, void, OrderPopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    url: `${rootPath}/token/${token}`,
  });

export const initPayment = async (data: InitPaymentRequestApiDto, options?: BaseOptions) =>
  apiRequest<PaymentResponseApiDto, InitPaymentRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${rootPath}/payment/init`,
  });

export const retryPayment = async (data: RetryPaymentRequestApiDto, options?: BaseOptions) =>
  apiRequest<PaymentResponseApiDto, RetryPaymentRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${rootPath}/payment/retry`,
  });

export const getPaymentMethods = async (code: string, options?: BaseOptions) =>
  apiRequest<Array<PaymentMethodApiDto>, void, void>({
    ...getBaseOptions(options),
    url: `${rootPath}/code/${code}/paymentMethods`,
  });

export interface SearchOrdersOptions extends BaseOptions {
  code?: string;
  email?: string;
  orderBy?: SearchOrderFieldApiType;
  orderingType?: OrderingApiType;
  pageNum?: number;
  pageSize?: number;
  pending?: boolean;
  populate?: OrderPopulate;

  state?: Array<OrderStateApiType>;
}

export const search = async (options?: SearchOrdersOptions) =>
  apiRequest<SearchOrdersApiDto, void, OrderPopulate>({
    ...getBaseOptions(options),
    params: {
      code: options?.code,
      email: options?.email,
      orderBy: options?.orderBy,
      orderingType: options?.orderingType,
      pageNum: options?.pageNum,
      pageSize: options?.pageSize,
      pending: options?.pending,
      state: options?.state,
    },
    populate: options?.populate,
    url: `${rootPath}/search`,
  });

export const getInvoiceFile = async (code: string, options?: BaseOptions) =>
  apiRequest<BlobPart, void, void>({
    ...getBaseOptions(options),
    responseType: 'blob',
    url: `${rootPath}/code/${code}/invoice/download`,
  });
