import { CartApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classNames from 'classnames';

import { appConfig } from './config';
import { FreeShippingThresholdContentType } from './contentTypes';
import { Div } from './HTMLElement';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import { ResponsivePaddingSize } from './interfaces';
import { ProgressBar } from './ProgressBar';
import { Link } from './router/Link';
import { formatCurrency, formatHtml, formatWeight } from './util';

export interface FreeShippingThresholdProps extends FreeShippingThresholdContentType {
  cart: CartApiDto;
  padding?: ResponsivePaddingSize;
}

export const FreeShippingThreshold = ({
  cart,
  enableContinueShopping = false,
  freeShippingThresholdCriterion = 'currency',
  padding,
}: FreeShippingThresholdProps) => {
  const containerPadding = padding ?? 3;

  return (
    <>
      {freeShippingThresholdCriterion === 'currency' && cart.remainingAmountToFreeShipping !== undefined && (
        <Div className="cart-to-free-shipping" marginBottom={3} padding={containerPadding} textAlign="center">
          <Div className="copy-container" dynamicMargin={{ bottom: 2 }}>
            <>
              <Div>
                {appConfig.icons?.freeShipping && <Icon name={appConfig.icons.freeShipping} />}
                {cart.remainingAmountToFreeShipping > 0
                  ? formatHtml(
                      t('cart.summary.toFreeShipping', {
                        toFreeShippingAmount: formatCurrency(cart.remainingAmountToFreeShipping),
                      })
                    )
                  : formatHtml(t('cart.summary.toFreeShippingComplete'))}
              </Div>
              {enableContinueShopping && (
                <Div margin={2} textAlign="center">
                  <Link className={classNames('my-2 text-decoration-underline text-reset')} to="/search">
                    {t('cart.summary.continueShopping')}
                  </Link>
                </Div>
              )}
            </>
          </Div>
          {cart.shippingProfile?.freeThreshold && (
            <ProgressBar current={cart.totalCost} hiddenCount max={cart.shippingProfile.freeThreshold} min={0} />
          )}
        </Div>
      )}
      {freeShippingThresholdCriterion === 'weight' && cart.remainingAmountToFreeShippingWeight !== undefined && (
        <Div className="cart-to-free-shipping" marginBottom={3} padding={containerPadding} textAlign="center">
          <Div className="copy-container" dynamicMargin={{ bottom: 2 }}>
            <>
              {cart.remainingAmountToFreeShippingWeight > 0
                ? formatHtml(
                    t('cart.summary.toFreeShipping', {
                      toFreeShippingAmount: formatWeight(cart.remainingAmountToFreeShippingWeight),
                    })
                  )
                : formatHtml(t('cart.summary.toFreeShippingComplete'))}
              {enableContinueShopping && (
                <Div margin={2} textAlign="center">
                  <Link className={classNames('my-2 text-decoration-underline text-reset')} to="/search">
                    {t('cart.summary.continueShopping')}
                  </Link>
                </Div>
              )}
            </>
          </Div>
          {cart.shippingProfile?.freeThresholdWeight && (
            <ProgressBar
              current={cart.totalWeight / 1000}
              hiddenCount
              max={cart.shippingProfile.freeThresholdWeight}
              min={0}
            />
          )}
        </Div>
      )}
    </>
  );
};
