import './Button.scss';

import classnames from 'classnames';
import React from 'react';

import { Icon, IconProps } from './Icon';
import { renderUI } from './util';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export type ButtonSize = 'small' | 'large' | undefined;
export type CommonButtonVariant = 'primary' | 'link' | 'blank' | 'plain' | 'pink' | 'outline-pink';

export interface ButtonProps<ButtonVariant, IconName, IconSize>
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
  alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
  alignWithFlex?: boolean;
  children?: React.ReactNode;
  iconEnd?: IconProps<IconName, IconSize>;
  iconStart?: IconProps<IconName, IconSize>;
  innerRef?: React.Ref<HTMLButtonElement>;
  justifyContent?: 'between' | 'start' | 'end' | 'center' | 'around' | 'evenly';
  label?: string | React.ReactElement;
  size?: ButtonSize;
  variant?: ButtonVariant;
}

const Button = <ButtonVariant extends string, IconName extends string, IconSize extends number>({
  alignItems,
  alignWithFlex,
  children,
  className,
  iconEnd,
  iconStart,
  innerRef,
  justifyContent,
  label,
  size,
  type = 'button',
  variant,
  ...otherProps
}: ButtonProps<ButtonVariant, IconName, IconSize>) => {
  return renderUI({
    bs5: (
      // eslint-disable-next-line react/forbid-elements
      <button
        {...otherProps}
        className={buildButtonClassName({ alignItems, alignWithFlex, className, justifyContent, size, variant })}
        ref={innerRef}
        type={type}
      >
        {buildButtonChildren({ alignWithFlex, children, iconEnd, iconStart, label })}
      </button>
    ),
  });
};

export const buildButtonClassName = ({
  alignItems = 'center',
  alignWithFlex = true,
  className,
  justifyContent = 'around',
  size,
  variant,
}: ButtonProps<string, string, number>) =>
  classnames(
    className,
    'Button btn',
    { [`btn-${variant ?? 'primary'}`]: variant !== 'plain' },
    { 'btn-sm': size === 'small' },
    { 'btn-lg': size === 'large' },
    { 'd-inline-flex gap-2': alignWithFlex },
    { [`align-items-${alignItems}`]: alignWithFlex && alignItems },
    { [`justify-content-${justifyContent}`]: alignWithFlex && justifyContent }
  );

export const buildButtonChildren = ({
  alignWithFlex = true,
  children,
  iconEnd,
  iconStart,
  label,
}: ButtonProps<string, string, number>) =>
  children ? (
    children
  ) : (
    <>
      {iconStart && (
        <Icon
          {...iconStart}
          className={classnames({ 'me-2': label !== undefined && !alignWithFlex }, iconStart.className)}
        />
      )}
      {label}
      {iconEnd && (
        <Icon
          {...iconEnd}
          className={classnames({ 'ms-2': label !== undefined && !alignWithFlex }, iconEnd.className)}
        />
      )}
    </>
  );

export type ButtonVariants = '';

const ButtonController = <ButtonVarian extends string, IconName extends string, IconSize extends number>(
  props: PropsWithCustomComponent<ButtonProps<ButtonVarian, IconName, IconSize>>
) => (
  <VariantsController<ButtonProps<ButtonVarian, IconName, IconSize>, ButtonVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: Button,
      name: 'Button',
    }}
  />
);
export { ButtonController as Button };
