import { wait } from '@testing-library/user-event/dist/utils';
import * as bootstrap5 from 'bootstrap-5';
import React from 'react';
import ReactDOM from 'react-dom';

import { PackedModalProps } from './Modal';
import { useModalsStaticContext } from './ModalsContext';
import { PackedOffcanvasProps } from './Offcanvas';
import { createUUID } from './util';

export const useModals = () => {
  const { setIds, setModals, setOffcanvasses } = useModalsStaticContext();

  // Uso questo trigger per anticipare lo useEffect in caso di chiusira di una modale,
  // al fine di aprire il modale precedente "mentre" si chiude l'attuale, non dopo.
  // const [trigger, setTrigger] = React.useState(0);

  const getElementIdByRef = React.useCallback((ref: React.RefObject<HTMLDivElement>) => {
    if (!ref.current) {
      throw new Error('Modal/Offcanvas with ref does not exists.');
    }
    return ref.current.id;
  }, []);

  const show = React.useCallback(
    (modalOrOffcanvas: Omit<PackedModalProps | PackedOffcanvasProps, 'id'>, type: 'modal' | 'offcanvas') => {
      const handlePostStateChange = () => {
        const element = getElementById(id);
        element.addEventListener(`hide.bs.${type}`, () => {
          element.dataset.closing = 'true';
          // setTrigger((prev) => prev + 1);
        });
        element.addEventListener(`hidden.bs.${type}`, () => {
          delete element.dataset.closing;
          if (element.dataset.keepForLater !== 'true') {
            const instance = getInstanceById(id);
            instance && instance.dispose();
            setIds((prevIds) => prevIds.filter((prevId) => prevId !== id));
            switch (type) {
              case 'modal':
                setModals((prevModals) => prevModals.filter((prevModal) => prevModal.id !== id));
                break;
              case 'offcanvas':
                setOffcanvasses((prevOffcanvasses) =>
                  prevOffcanvasses.filter((prevOffcanvas) => prevOffcanvas.id !== id)
                );
                break;
            }
          }
        });
      };

      const id = createUUID();

      switch (type) {
        case 'modal': {
          const modal = modalOrOffcanvas as PackedModalProps;
          // setModals((prevModals) => prevModals.concat({ ...modal, id: id })).then(() => {
          //   handlePostStateChange();
          // });
          ReactDOM.flushSync(() => {
            setModals((prevModals) => prevModals.concat({ ...modal, id: id }));
          });
          wait(500).then(() => {
            handlePostStateChange();
          });
          break;
        }
        case 'offcanvas':
          const offcanvas = modalOrOffcanvas as PackedOffcanvasProps;
          // setOffcanvasses((prevOffcanvasses) => prevOffcanvasses.concat({ ...offcanvas, id: id })).then(() => {
          //   handlePostStateChange();
          // });
          ReactDOM.flushSync(() => {
            setOffcanvasses((prevOffcanvasses) => prevOffcanvasses.concat({ ...offcanvas, id: id }));
          });
          wait(500).then(() => {
            handlePostStateChange();
          });
          break;
      }

      setIds((prevIds) => prevIds.concat(id));

      return id;
    },
    [setIds, setModals, setOffcanvasses]
  );

  const showModal = React.useCallback(
    (modal: Omit<PackedModalProps, 'id'>) => {
      return show(modal, 'modal');
    },
    [show]
  );

  const showOffcanvas = React.useCallback(
    (offcanvas: Omit<PackedOffcanvasProps, 'id'>) => {
      return show(offcanvas, 'offcanvas');
    },
    [show]
  );

  const showByRef = React.useCallback(
    (ref: React.RefObject<HTMLDivElement>, type: 'modal' | 'offcanvas') => {
      const id = getElementIdByRef(ref);
      // setIds((prevIds) => prevIds.concat(id)).then(() => {
      //   const element = getElementById(id);
      //   element.addEventListener(`hide.bs.${type}`, () => {
      //     element.dataset.closing = 'true';
      //     setTrigger((prev) => prev + 1);
      //   });
      //   element.addEventListener(`hidden.bs.${type}`, () => {
      //     delete element.dataset.closing;
      //     if (element.dataset.keepForLater !== 'true') {
      //       const instance = getInstanceById(id);
      //       instance && instance.dispose();
      //       setIds((prevIds) => prevIds.filter((prevId) => prevId !== id));
      //     }
      //   });
      // });
      ReactDOM.flushSync(() => {
        setIds((prevIds) => prevIds.concat(id));
      });
      wait(500).then(() => {
        const element = getElementById(id);
        element.addEventListener(`hide.bs.${type}`, () => {
          element.dataset.closing = 'true';
          // setTrigger((prev) => prev + 1);
        });
        element.addEventListener(`hidden.bs.${type}`, () => {
          delete element.dataset.closing;
          if (element.dataset.keepForLater !== 'true') {
            const instance = getInstanceById(id);
            instance && instance.dispose();
            setIds((prevIds) => prevIds.filter((prevId) => prevId !== id));
          }
        });
      });
    },
    [getElementIdByRef, setIds]
  );

  const addEventListener = React.useCallback(
    (
      id: string,
      event: 'show' | 'shown' | 'hide' | 'hidden' | 'hidePrevented',
      type: 'modal' | 'offcanvas',
      callback: () => void,
      alsoWhenKeepingForLater = false
    ) => {
      if (event === 'hidePrevented' && type === 'offcanvas') {
        throw new Error("Event 'hidePrevented' not supported on offcanvas");
      }
      const element = getElementById(id);

      const eventId = `${event}.bs.${type}`;
      const listener = () => {
        if (alsoWhenKeepingForLater || element.dataset.keepForLater !== 'true') {
          callback();
        }
      };

      element.addEventListener(eventId, listener);

      const dispose = () => {
        element.removeEventListener(eventId, listener);
      };

      return dispose;
    },
    []
  );

  const close = React.useCallback(
    (id: string) =>
      new Promise<void>((resolve) => {
        const element = getElementById(id);
        addEventListener(id, 'hidden', getType(element), () => {
          resolve();
        });
        element.dataset.keepForLater = 'false';
        const instance = getInstanceById(id);
        instance && instance.hide();
      }),
    [addEventListener]
  );

  const closeByRef = React.useCallback(
    (ref: React.RefObject<HTMLDivElement>) => {
      const id = getElementIdByRef(ref);
      return close(id);
    },
    [close, getElementIdByRef]
  );

  return { addEventListener, close, closeByRef, showByRef, showModal, showOffcanvas };
};

const isModal = (element: HTMLElement) => {
  return element.classList.contains('modal');
};

const isOffcanvas = (element: HTMLElement) => {
  return element.classList.contains('offcanvas');
};

const getType = (element: HTMLElement): 'modal' | 'offcanvas' => {
  if (isModal(element)) {
    return 'modal';
  }
  if (isOffcanvas(element)) {
    return 'offcanvas';
  } else {
    throw new Error('Cannot detect modal type');
  }
};

export const getElementById = (id: string) => {
  const element = document.getElementById(id);
  if (!element) {
    throw new Error(`Modal/Offcanvas with id ${id} does not exists.`);
  }
  return element;
};

export const getInstanceById = (id: string) => {
  const element = getElementById(id);
  if (isModal(element)) {
    const instance = bootstrap5.Modal.getOrCreateInstance(element);
    return instance;
  } else if (isOffcanvas(element)) {
    const instance = bootstrap5.Offcanvas.getOrCreateInstance(element);
    return instance;
  }
};
